//土耳其
export default {
	accountDetailsPage: {
	    accountDetails: 'Hesap Detayları',
	    startDate: 'Başlangıç Tarihi',
	    endDate: 'Bitiş Tarihi',
	    search: 'Ara',
	    alltypes: 'Tüm Türler',
	    withdrawalRecord: 'Para Çekme Kaydı',
	    rechargeRecord: 'Para Yükleme Kaydı',
	    withDrawal: 'Para Çekme',
	    UAddress: 'U Adresi',
	    rebateForOrderGrabbing: 'Sipariş Yakalama İadesi',
	    orderGrab: 'Sipariş Yakalamak',
	    recharge: 'Para Yüklemek',
	    tryToLoad: 'Yükleniyor...',
	    noMore: 'Daha Fazla Yok',
	    cancel: 'İptal',
	    ok: 'Tamam',
	    pleaseSelectATime: 'Lütfen bir zaman seçin'
	},

    teamPage:{
		 teamReport: 'Ekip Raporu',
		 startDate: 'Başlangıç Tarihi',
		 endDate: 'Bitiş Tarihi',
		 search: 'Ara',
		 teamBalance: 'Ekip Bakiyesi ($)',
		 teamFlow: 'Ekip Akışı ($)',
		 totalTeamRecharge: 'Ekip Toplam Yükleme ($)',
		 theTotalWithdrawalOfTheTeam: 'Ekip Toplam Para Çekme ($)',
		 teamOrderCommission: 'Ekip Sipariş Komisyonu ($)', 
		 myCommission: 'Benim Komisyonum ($)',
		 directPushNumber: 'Doğrudan Yönlendirme Sayısı', 
		 teamSize: 'Ekip Büyüklüğü', 
		 todaysNewNumberOfPeople: 'Bugün Eklenen Sayı',
		 aysActiveNumber: 'Bugün Aktif Sayı',
		 level1: 'Seviye 1',
		 level2: 'Seviye 2',
		 level3: 'Seviye 3',
		 tryToLoad: 'Yükleniyor...',
		 noMore: 'Daha Fazla Yok',
		 mobilePhone: 'Telefon Numarası', 
		 Number: 'Yönlendirdiği Kişi Sayısı',
		 withdrawals: 'Para Çekme', 
		 recharge: 'Yükleme',
		 time: 'Kayıt Zamanı'
    },
	
    culturePage: {
        title: 'Şirket Nitelikleri',
        text: 'AppLovin, “Müşteri Odaklı” ilkesine bağlı kalarak, “Her Gün Düşük Fiyat, Orijinal Ürün” inancıyla, kitap, bilgisayar, dijital ev aletleri, bebek ürünleri, giyim ve çanta gibi milyonlarca ürün satışı gerçekleştirmektedir. AppLovin Çin, profesyonel hizmet sunmaktadır: Orijinal ürünler her gün düşük fiyatla, makbuzlu fatura ile ülke genelinde garanti. Kapıda ödeme, 30 gün içinde iade ve değişim imkanı. AppLovin, küresel tüketicilere kolay, hızlı bir çevrimiçi alışveriş deneyimi sunmaktadır.'
    },

    // 登录页
    loginPage:{
        mobileNumber: 'Telefon Numarası', 
        mobileNumberPlaceholder: 'Lütfen telefon numarasını girin',
        mobileNumberMessage: 'Lütfen geçerli bir telefon numarası girin',
        password: 'Şifre',
        passwordPlaceholder: 'Lütfen şifreyi girin',
        passwordMessage: 'Girdiğiniz şifre hatalı, lütfen tekrar deneyin',
        forgotPassword: 'Şifremi Unuttum',
        goToRegister: 'Kayıt Ol',
        rememberPassword: 'Şifreyi Hatırla',
        login: 'Giriş Yap',
        noAccountYet: 'Henüz hesabınız yok mu?',
        loginSuccessful: 'Giriş başarılı!',
        loginFailed: 'Giriş başarısız!'
      },
    //   注册页
      registerPage:{
		 register: 'Kayıt Ol',
		 mobileNumber: 'Telefon Numarası',
		 mobileNumberPlaceholder: 'Lütfen telefon numarasını girin',
		 mobileNumberMessage: 'Lütfen geçerli bir telefon numarası girin',
		 loginPassword: 'Giriş Parolası',
		 loginPasswordPlaceholder: 'Lütfen giriş parolasını girin',
		 loginPasswordMessage: 'Belirlediğiniz giriş parolası boş olamaz',
		 invitationCode: 'Davet Kodu',
		 invitationCodePlaceholder: 'Lütfen davet kodunu girin',
		 invitationCodeMessage: 'Girdiğiniz davet kodu hatalı',
		 fundingPassword: 'Fon Parolası',
		 fundingPasswordPlaceholder: 'Lütfen fon parolasını girin',
		 fundingPasswordMessage: 'Belirlediğiniz fon parolası boş olamaz',
		 iKnowAndAgree: 'Durumu anladım ve kabul ediyorum',
		 accountOpeningAgreement: 'Hesap Açma Sözleşmesi',
		 theTreaties: 'Çeşitli Sözleşmeler',
		 signUpNow: 'Hemen Kayıt Ol',
		 pleaseAgree: 'Lütfen önce kayıt sözleşmesini kabul edin',
		 existingAccount: 'Zaten bir hesabınız var',
		 loginNow: 'Hemen Giriş Yap',
		 registrationFailed: 'Kayıt başarısız',
		 registrationSuccessful: 'Kayıt başarılı',
		 iKnow: 'Biliyorum',
		 accountOpen: 'Hesap Açma Sözleşmesi'

      },
      // my页面
      myPage:{
		logout: 'Çıkış Yap',
		areYouSureYouWantToLogOut: 'Çıkış yapmak istediğinizden emin misiniz?',
		confirnText: 'Tamam',
		cancleText: 'İptal',
		amazonReputation: 'İtibar Puanı:',
		accountBalance: 'Hesap Bakiyesi',
		recharge: 'Yükleme',
		withdrawal: 'Para Çekme',
		deliveryAddress: 'Teslimat Adresi',
		iWantToPromote: 'Tanıtım Yapmak İstiyorum',
		secondPurchaseRecord: 'İkinci Alım Kaydı',
		accountDetails: 'Hesap Detayları',
		teamReport: 'Ekip Raporu',
		companyQualification: 'Şirket Nitelikleri',
		platformRules: 'Platform Kuralları'
      },
      // 充值页面
		rechargePage: {
			recharge: 'Yükleme',
			currentBalance: 'Mevcut Bakiyeniz ($)',
			rechargeApplication: 'Yükleme Başvurusu Yapılıyor ($)',
			accumulatedRecharged: 'Toplam Yüklenen Miktar ($)',
			rechargeAmount: 'Yükleme Miktarı',
			pleaseEnterTheRechargeAmount: 'Lütfen yükleme miktarını girin',
			rechargeNow: 'Hemen Yükle'
		},

		// Sipariş Yakalama Listesi Sayfası
		grabListPage: {
			failedToGetTheOrderGrabList: 'Sipariş yakalama listesi alınamadı'
		},

      // 提现页面
      withDrawalPage:{
		withDrawal: 'Para Çekme',
		  currentBalance: 'Mevcut Tutar ($)',		  
		  withdrawalApplicationInProgress: 'Para Çekme Başvurusu Yapılıyor ($)',		  
		  cumulativelyWithdrawn: 'Toplam Para Çekilen Miktar ($)',		  
		  withdrawalAmount: 'Para Çekme Miktarı',		  
		  pleaseEnterTheWithdrawalAmount: 'Lütfen para çekme miktarını girin',		  
		  withdrawAll: 'Tümünü Çek',		  
		  uAddress: 'U Adresi',		  
		  pleaseEnterUAddress: 'Lütfen U adresini girin',		  
		  transactionPassword: 'İşlem Parolası',		  
		  pleaseEnterTheTransactionPassword: 'Lütfen işlem parolasını girin',		  
		  withdrawNow: 'Hemen Para Çek',		  
		  allFieldsCannotBeEmpty: 'Tüm alanlar boş bırakılamaz',		  
		  able: 'Çekilebilir Miktar',		  
		  networkType: 'Ağ Türü',		  
		  minPrice: 'Minimum para çekme miktarı $100',		  
		  minXinyufen: 'Kredi puanı yetersiz, çekim yapılamaz, lütfen müşteri hizmetleri ile iletişime geçin!'
   },
      // 主页页面
   indexPage: {
       indexList: 'Ana sayfa verisi alınamadı',
   
       amazonLetMiaogouOpenYourWayToWealth: 'AppLovin, anında satın almayı zenginliğinize açıyor',
   
       myAssets: 'Benim Varlıklarım ($)',
   
       recharge: 'Yükleme',
   
       withdrawal: 'Para Çekme',
   
       yesterdaysIncome: 'Dünkü Gelir ($)',
   
       cumulativeIncome: 'Toplam Gelir ($)',
   
       todaysIncome: 'Bugünkü Gelir ($)',
   
       freezeFunds: 'Buzdolabındaki Fonlar ($)',
   
       regularFinancialManagement: 'Düzenli Finansal Yönetim',
   
       quickRecharge: 'Hızlı Yükleme',
   
       quickWithdrawal: 'Hızlı Para Çekme',
   
       inviteFriends: 'Arkadaşları Davet Et',
   
       taskHall: 'Görev Salonu',
   
       unlocked: 'Açıldı',
   
       service: 'Müşteri Hizmetleri',
   
       leaderboard: 'Para Çekme Kaydı'
   },
   
   addressPage: {
       address: 'Adres',
   
       realName: 'Gerçek İsim',
   
       pleaseEnterYourRealName: 'Lütfen gerçek isminizi girin',
   
       mobileNumber: 'Telefon Numarası',
   
       pleaseEnterThePhoneNumber: 'Lütfen telefon numarasını girin',
   
       region: 'Bölge',
   
       pleaseEnterTheRegion: 'Lütfen bölgeyi girin',
   
       detailedAddress: 'Detaylı Adres',
   
       pleaseEnterDetailedAddress: 'Lütfen detaylı adresi girin',
   
       submit: 'Gönder'
   },
   
   // order页面
   orderPage: {
       grabAnOrder: 'Sipariş Yakalama Devam Ediyor',
   
       hiShop: 'Merhaba Alışveriş',
   
       winTheFutureWithYou: 'Geleceği Sizinle Kazanalım',
   
       orderText: 'AppLovin 1994 yılında kuruldu ve 1 milyondan fazla e-ticaret şirketiyle işbirliği yaparak her gün 150.000 ile 30 milyon siparişi yönetiyor. Fon yönetimi varlık yönetim şirketine devredildiği için, kullanıcıların iade talepleri ve e-ticaret şirketlerinin fon kaçakları önlenebilir. Akıllı bulut istemcisi ve kullanıcı IP’leri belirlenen kurallara göre kullanılır. Otomatik sipariş mücadelesi, satıcıların sipariş yakalayan kullanıcıların hatalarından dolayı kapatılmasını ve düşürülmesini engelleyecektir. Bir aracı olarak, satıcıların ve kullanıcıların sipariş mücadelesinde güven ve verimliliklerini artırmaya devam edeceğiz!',
   
       bronzeMember: 'Bronz Üye',
   
       commission: 'Komisyon',
   
       Matching: 'Siparişiniz eşleştiriliyor, lütfen bekleyin',
   
       dispatch: 'Gönderim',
   
       grabAnOrder: 'Sipariş Yakala',
   
       balance: 'Bakiye',
   
       recharge: 'Yükleme',
   
       withdrawal: 'Para Çekme',
   
       matchingRules: 'Eşleştirme Kuralları',
   
       commissionEarnedToday: 'Bugün Kazanılan Komisyon',
   
       Today: 'Bugün Yakalanan Sipariş Sayısı',
   
       oddNumber: 'Bugün Kalan Sipariş Sayısı',
   
       yesterdays: 'Dünkü Komisyon',
   
       User: 'Kullanıcı',
   
       getCommission: 'Komisyon Al'
   },
   
   // Popularize页面
   popularizePage: {
       iWantToPromote: 'Tanıtım Yapmak İstiyorum',
   
       inviteNewUsers: 'Yeni Kullanıcıları Davet Et',
   
       winTheFutureWithYou: 'Geleceği Sizinle Kazanalım',
   
       screenshot: 'Ekran görüntüsü ile QR kodunu kaydet',
   
       friend: 'Arkadaş Tanıma ve Kaydolma',
   
       complete: 'Tamamla ve Sipariş Yakala',
   
       distribute: '24 saat içinde nakit hediye dağıt',
   
       invitationCode: 'Davet Kodu:'
   },
   
   // list页面
   listPage: {
       secondPurchaseRecord: 'İkinci Alım Kaydı',
   
       this: 'Bu veri, çeşitli iş ortaklarından sağlanmaktadır',
   
       Remaining: 'Kalan Kullanılabilir Varlıklar ($)',
   
       allOrders: 'Tüm Siparişler',
   
       toBeProcessed: 'İşlem Görmeyi Bekleyen',
   
       completed: 'Tamamlanmış',
   
       freezing: 'Buzdolabında',
   
       totalAmountOfTheOrder: 'Sipariş Toplam Bakiyesi',
   
       commission: 'Komisyon',
   
       Estimated: 'Tahmini Komisyon',
   
       submitOrder: 'Siparişi Gönder',
   
       secondpurchasetime: 'Anında Alım Zamanı:',
   
       secondPurchaseNumber: 'Anında Alım Numarası:',
   
       NotSubmitted: 'Henüz Gönderilmedi',
   
       submitNow: 'Hemen Gönder'
   },
   
   vipPage: {
       'agent_mode': 'Ajan Modu',
   
       'current_level': 'Mevcut Seviye',
   
       'vip1_text1': '40 adet uygulama veri görevi al',
   
       'vip1_text2': 'Her bir başvuru için %0.50 kar',
   
       'vip1_text3': '100.00 USDT ile aktive et',
   
       'vip2_text1': '50 adet uygulama veri görevi al',
   
       'vip2_text2': 'Her bir başvuru için %0.60 kar',
   
       'vip2_text3': '2,000.00 USDT ile aktive et',
   
       'vip3_text1': '55 adet uygulama veri görevi al',
   
       'vip3_text2': 'Her bir başvuru için %0.80 kar',
   
       'vip3_text3': '5,000.00 USDT ile aktive et',
   
       'vip4_text1': '60 adet uygulama veri görevi al',
   
       'vip4_text2': 'Her bir başvuru için %1.00 kar',
   
       'vip4_text3': '10,000.00 USDT ile aktive et'
   }

}